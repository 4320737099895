export const markers = [
  { markerOffset: 10, name: "Marseille", coordinates: [5.37, 43.296398] },
  {
    markerOffset: 10,
    name: "Marseille2",
    coordinates: [5.41822966328081, 43.21530495160203],
  },
  {
    markerOffset: 10,
    name: "Marseille3",
    coordinates: [5.319969618423781, 43.35426471317352],
  },
  { markerOffset: 10, name: "Lyon", coordinates: [4.834277, 45.76342] },
  { markerOffset: 10, name: "Montpellier", coordinates: [3.876716, 43.610769] },
  { markerOffset: 10, name: "Toulouse", coordinates: [1.444209, 43.604652] },
  { markerOffset: 10, name: "Amiens", coordinates: [2.295753, 49.894067] },
  { markerOffset: 10, name: "Lourdes", coordinates: [-0.045726, 43.091463] },
  {
    markerOffset: 10,
    name: "Stade de France",
    coordinates: [2.3640389442443848, 48.92440414428711],
  },
  { markerOffset: 10, name: "Lausanne", coordinates: [6.633597, 46.519962] },
  {
    markerOffset: 10,
    name: "Stockholm",
    coordinates: [18.031820595603925, 59.308423072372086],
  },
  {
    markerOffset: 10,
    name: "Stockholm2",
    coordinates: [18.031820595603925, 59.32],
  },
  { markerOffset: 10, name: "Madrid", coordinates: [-3.70379, 40.416775] },
  { markerOffset: 10, name: "Ibiza", coordinates: [1.421416, 38.906986] },
  {
    markerOffset: 10,
    name: "Mallorca",
    coordinates: [3.1712148033278953, 39.33044898487393],
  },
  { markerOffset: 10, name: "Mallorca2", coordinates: [2.650544, 39.571625] },
  {
    markerOffset: 10,
    name: "Mallorca3",
    coordinates: [3.1227426181844793, 39.836053742204605],
  },
  { markerOffset: 10, name: "Rome", coordinates: [12.496366, 41.902782] },
  {
    markerOffset: 10,
    name: "Madere",
    coordinates: [-16.91549344141909, 32.6524447905132],
  },
  {
    markerOffset: 10,
    name: "Madere2",
    coordinates: [-16.929404221190854, 32.73656459107169],
  },
  { markerOffset: 10, name: "Amsterdam", coordinates: [4.896029, 52.371807] },
  { markerOffset: 10, name: "Montreal", coordinates: [-73.567256, 45.5016889] },
  {
    markerOffset: 15,
    name: "Aix-en-Provence",
    coordinates: [5.447427, 43.529742],
  },
  {
    markerOffset: 10,
    name: "St Evroult notre dame du bois",
    coordinates: [1.471042, 48.64808],
  },
  {
    markerOffset: 10,
    name: "Bruxelles",
    coordinates: [4.3517103, 50.8503396],
  },
  {
    markerOffset: 10,
    name: "Pointe-à-Pitre",
    coordinates: [-61.5331, 16.2411],
  },
  {
    markerOffset: 10,
    name: "Pointe de la grande vigie",
    coordinates: [-61.466374810153276, 16.51000870044643],
  },
  {
    markerOffset: 10,
    name: "Saint-Denis",
    coordinates: [55.536384, -21.115141],
  },
  {
    markerOffset: 10,
    name: "Montreal2",
    coordinates: [-73.6, 45.5],
  },
  {
    markerOffset: 10,
    name: "Montreal3",
    coordinates: [-73.7, 45.5],
  },
  {
    markerOffset: 10,
    name: "Montreal4",
    coordinates: [-73.567256, 45.6],
  },
  {
    markerOffset: 10,
    name: "New York",
    coordinates: [-74.0420135, 40.6985935],
  },
  {
    markerOffset: 10,
    name: "New York2",
    coordinates: [-73.970702, 40.575544],
  },
  {
    markerOffset: 10,
    name: "San Francisco",
    coordinates: [-122.47219820033433, 37.80795247019886],
  },
  {
    markerOffset: 10,
    name: "San Francisco2",
    coordinates: [-122.431297, 37.773972],
  },
  {
    markerOffset: 10,
    name: "San Francisco3",
    coordinates: [-122.48092600239343, 37.800224267087195],
  },
  {
    markerOffset: 10,
    name: "Berlin",
    coordinates: [13.405, 52.52],
  },
  {
    markerOffset: 10,
    name: "Tulum",
    coordinates: [-87.430588, 20.214788],
  },
  {
    markerOffset: 10,
    name: "Lanzarote",
    coordinates: [-13.633, 29.035],
  },
  {
    markerOffset: 10,
    name: "Tokyo",
    coordinates: [139.839478, 35.652832],
  },
  {
    markerOffset: 10,
    name: "Zurich",
    coordinates: [8.541694, 47.376887],
  },
  {
    markerOffset: 10,
    name: "Europa Park",
    coordinates: [7.722008, 48.266019],
  },
  {
    markerOffset: 10,
    name: "Forcalquier",
    coordinates: [5.780712, 43.959933],
  },
  {
    markerOffset: 10,
    name: "Marrakech",
    coordinates: [-7.992047, 31.628674],
  },
  {
    markerOffset: 10,
    name: "Désert du Maroc",
    coordinates: [-5.894861404890042, 30.340217431619806],
  },
  {
    markerOffset: 10,
    name: "Jardin Majorelle",
    coordinates: [-8.00321824555253, 31.641175672174896],
  },
  {
    markerOffset: 10,
    name: "Miradouro do Chrissy",
    coordinates: [-8.609889919771653, 41.13743640480617],
  },
  {
    markerOffset: 10,
    name: "Grahams",
    coordinates: [-8.625107231772393, 41.13822427454951],
  },
  {
    markerOffset: 10,
    name: "Pont Dom-Luis",
    coordinates: [-8.612464187367857, 41.14041911949847],
  },
  {
    markerOffset: 10,
    name: "Jardim Do Infante Dom Henrique",
    coordinates: [-8.614647591103159, 41.14138568558311],
  },
  {
    markerOffset: 10,
    name: "Lisbonne",
    coordinates: [-9.151899024313144, 38.70868707728141],
  },
  {
    markersOffset: 10,
    name: "Lisbonne2",
    coordinates: [-9.143194723784838, 38.720910780842075],
  },
  {
    markersOffset: 10,
    name: "Lisbonne3",
    coordinates: [-9.133254829782988, 38.713692429563075],
  },
  {
    markerOffset: 10,
    name: "Pont de Belem",
    coordinates: [-9.215648488122516, 38.69165167968937],
  },
  {
    markerOffset: 10,
    name: "Tour de Belem",
    coordinates: [-9.216020218309305, 38.69170090900275],
  },
  {
    markerOffset: 10,
    name: "Galerie Dior",
    coordinates: [2.3072350615393034, 48.86702207468827],
  },
  {
    markerOffset: 10,
    name: "Turin",
    coordinates: [7.742615, 45.116177],
  },
  {
    markerOffset: 10,
    name: "La Valette",
    coordinates: [14.511749168141261, 35.900162238863665],
  },
  {
    markerOffset: 10,
    name: "La Valette2",
    coordinates: [14.51288413667211, 35.89709095632821],
  },
  {
    markerOffset: 10,
    name: "La Valette3",
    coordinates: [14.50908048888165, 35.89849239408039],
  },
  {
    markerOffest: 10,
    name: "Forti Sant' Anġlu",
    coordinates: [14.51804063498434, 35.891962041284415],
  },
  {
    markerOffset: 10,
    name: "Lr Rabat",
    coordinates: [14.396789300999332, 35.87553043281012],
  },
  {
    markerOffset: 10,
    name: "Santiago",
    coordinates: [-70.65519620044284, -33.45838053928239],
  },
  {
    markerOffset: 10,
    name: "Rio de Janeiro",
    coordinates: [-43.210485837239894, -22.951866416716054],
  },
  {
    markerOffset: 10,
    name: "Rio de Janeiro2",
    coordinates: [-43.176315916049596, -22.93454550395706],
  },
  {
    markerOffset: 10,
    name: "Miami",
    coordinates: [-80.13329080296836, 25.768402538504976],
  },
  {
    markerOffset: 10,
    name: "Miami2",
    coordinates: [-80.12968276709722, 25.77515820335491],
  },
  {
    markerOffset: 10,
    name: "Miami3",
    coordinates: [-80.12804425562268, 25.782410131963644],
  },
  {
    markerOffset: 10,
    name: "Miami4",
    coordinates: [-80.13975034564444, 25.77745243834158],
  },
  {
    markerOffset: 10,
    name: "Oiapoque",
    coordinates: [-51.87239333301455, 3.7994724185137754],
  },
  {
    markerOffset: 10,
    name: "Oiapoque2",
    coordinates: [-51.83458110866848, 3.8433646758696223],
  },
  {
    markerOffset: 10,
    name: "Cayenne",
    coordinates: [-52.25770648523117, 4.898309038753106],
  },
  {
    markerOffset: 10,
    name: "Centre Georges Pompidou",
    coordinates: [2.3522449974752258, 48.8607549059754],
  },
  {
    markerOffset: 10,
    name: "Parc Astérix",
    coordinates: [2.5713268570070325, 49.134317146768694],
  },
  {
    markerOffset: 10,
    name: "Santorin",
    coordinates: [25.37510399067182, 36.461618385316875],
  },
  {
    markerOffset: 10,
    name: "Tanzanie",
    coordinates: [37.69735768470563, -8.441809228685202],
  },
  {
    markerOffset: 10,
    name: "Zanzibar",
    coordinates: [39.19686681681648, -6.1657191247598195],
  },
  {
    markerOffset: 10,
    name: "Vannes",
    coordinates: [-2.7592664383017818, 47.6583779649433],
  },
  {
    markerOffset: 10,
    name: "Salar d'Uyuni",
    coordinates: [-67.620552, -20.266562],
  },
  {
    markerOffset: 10,
    name: "Sintra",
    coordinates: [-9.391497783366454, 38.78222941403722],
  },
  {
    markerOffset: 10,
    name: "Désert d'Atacama",
    coordinates: [-69.13285053310715, -23.862393181118176],
  },
  {
    markerOffset: 10,
    name: "Machu Picchu",
    coordinates: [-72.54526978800729, -13.163198343222454],
  },
  {
    markerOffset: 10,
    name: "Isla del sol",
    coordinates: [-69.15232413353114, -16.028999713296518],
  },
  {
    markerOffset: 10,
    name: "Budapest",
    coordinates: [19.07787331205903, 47.51505873647904],
  },
  {
    markerOffset: 10,
    name: "Bruges",
    coordinates: [3.223365419015781, 51.20844155897161],
  },
  {
    markerOffset: 10,
    name: "Bruges2",
    coordinates: [3.22356904738374, 51.20040432469226],
  },
  {
    markerOffset: 10,
    name: "Pointe du Grouin",
    coordinates: [-1.8443683449880603, 48.713316991557065],
  },
  {
    markerOffset: 10,
    name: "Gran Canaria",
    coordinates: [-15.578053095941756, 27.744237535790557],
  },
  {
    markerOffset: 10,
    name: "Langevin",
    coordinates: [55.64186142532208, -21.31159369489214],
  },
  {
    markerOffset: 10,
    name: "Chambery",
    coordinates: [5.931929298356173, 45.58859437410209],
  },
  {
    markerOffset: 10,
    name: "Penerf",
    coordinates: [-2.624932319164334, 47.50838398058444],
  },
  {
    markerOffset: 10,
    name: "Plage La Caravelle",
    coordinates: [-61.39682963682821, 16.2189230631413],
  },
  {
    markerOffset: 10,
    name: "Saut Acomat",
    coordinates: [-61.760520452959796, 16.20972828122372],
  },
  {
    markerOffset: 10,
    name: "Petite Terre",
    coordinates: [-61.11212226234941, 16.17498315609246],
  },
  {
    markerOffset: 10,
    name: "Hamburg",
    coordinates: [9.986962497998515, 53.55208103567464],
  },
  {
    markerOffset: 10,
    name: "Miniatur Wunderland",
    coordinates: [9.989025814894811, 53.54386150074918],
  },
  {
    markerOffest: 10,
    name: "Poilly-Lez-Gien",
    coordinates: [2.5950565733606563, 47.65917184645986],
  },
  {
    markerOffset: 10,
    name: "Cartagena",
    coordinates: [-75.54871817396076, 10.42599580883838],
  },
  {
    markerOffest: 10,
    name: "Creuse",
    coordinates: [1.9781934991463865, 46.0639999861967],
  },
  {
    markerOffest: 10,
    name: "Carantec",
    coordinates: [-3.917988756585114, 48.67303858415854],
  },
  {
    markerOffest: 10,
    name: "Tunisie",
    coordinates: [9.148790371902862, 32.96374124585372],
  },
  {
    markerOffest: 10,
    name: "Mijas Pueblo",
    coordinates: [-4.6398098379457, 36.5950875687946],
  },
  {
    markerOffest: 10,
    name: "Zurich2",
    coordinates: [8.493310413611997, 47.41580584845233],
  },
  {
    markerOffest: 10,
    name: "Lac de creno",
    coordinates: [8.94599968707371, 42.205769396612936],
  },
  {
    markerOffest: 10,
    name: "Porto",
    coordinates: [8.704851073034531, 42.26587071121251],
  },
  {
    markerOffest: 10,
    name: "Ardèche",
    coordinates: [4.594599986054022, 44.72436529852495],
  },
  {
    markerOffest: 10,
    name: "South Dorset",
    coordinates: [-2.457119864259257, 50.56687615177141],
  },
  {
    markerOffest: 10,
    name: "Le Bourget",
    coordinates: [2.435161555281987, 48.94764164521462],
  },
  {
    markerOffest: 10,
    name: "Montpellier2",
    coordinates: [3.890733329925152, 43.58817446734194],
  },
  {
    markerOffest: 10,
    name: "Geneve",
    coordinates: [6.148409052909131, 46.207187236148656],
  },
  {
    markerOffest: 10,
    name: "Amsterdam2",
    coordinates: [4.891314011944643, 52.36219187839545],
  },
  {
    markerOffest: 10,
    name: "Bačvice",
    coordinates: [16.446760048857456, 43.50241471149333],
  },
  {
    markerOffest: 10,
    name: "Hvar",
    coordinates: [16.44073053323561, 43.17232592589241],
  },
  {
    markerOffest: 10,
    name: "Kermesse Festival",
    coordinates: [5.8844525999999995, 43.101811299999994],
  },
  {
    markerOffest: 10,
    name: "Leipzig",
    coordinates: [12.346923246022742, 51.345839613166866],
  },
  {
    markerOffest: 10,
    name: "Leipzig2",
    coordinates: [12.349669827891658, 51.34680460817152],
  },
  {
    markerOffest: 10,
    name: "Nice",
    coordinates: [7.270555821438131, 43.694984928345356],
  },
  {
    markerOffest: 10,
    name: "Ermioni",
    coordinates: [23.250454446352837, 37.38286324227004],
  },
  {
    markerOffest: 10,
    name: "Port Blanc",
    coordinates: [-3.3108624397620527, 48.83592967983588],
  },
  {
    markerOffest: 10,
    name: "Port Blanc2",
    coordinates: [-3.3189778217239336, 48.838306241259836],
  },
  {
    markerOffest: 10,
    name: "Mont Saint-Michel",
    coordinates: [-1.5101588758992932, 48.633889620141275],
  },
  {
    markerOffest: 10,
    name: "Marjevols",
    coordinates: [3.2811490916284134, 44.54821694450996],
  },
  {
    markerOffest: 10,
    name: "Rocamadour",
    coordinates: [1.6172157653039092, 44.799196678546465],
  },
  {
    markerOffest: 10,
    name: "Choranche",
    coordinates: [5.3959373570241675, 45.08340703755228],
  },
  {
    markerOffest: 10,
    name: "Phuket",
    coordinates: [98.40072304938143, 7.873868570256848],
  },
  {
    markerOffest: 10,
    name: "Massif Chartreuse",
    coordinates: [5.82269529057613, 45.391845332573396],
  },
  {
    markerOffest: 10,
    name: "Pekin",
    coordinates: [116.41558662358115, 39.88984562182031],
  },
  {
    markerOffest: 10,
    name: "Pouilles",
    coordinates: [16.91881910991998, 41.02544825756914],
  },
  {
    markerOffest: 10,
    name: "Torres del Paine",
    coordinates: [-73.09939472857253, -49.29545577872744],
  },
  {
    markerOffest: 10,
    name: "San Pedro de Atacama",
    coordinates: [-68.20012610110776, -22.895273690425025],
  },
  {
    markerOffest: 10,
    name: "Sucre",
    coordinates: [-65.25991890938997, -19.048148175121597],
  },
  {
    markerOffest: 10,
    name: "Topaz Princess Mermaid",
    coordinates: [129.15463638095088, 35.15476368188274],
  },
  {
    markerOffest: 10,
    name: "Geyongju",
    coordinates: [129.20850517938462, 35.84424453272652],
  },
  {
    markerOffest: 10,
    name: "Arequipa",
    coordinates: [-71.53660259831287, -16.396203267705594],
  },
  {
    markerOffset: 10,
    name: "Salar d'Uyuni2",
    coordinates: [-68.01524353621032, -20.061307579990203],
  },
  {
    markerOffset: 10,
    name: "Osaka",
    coordinates: [135.5134005646842, 34.700175134365445],
  },
  
];
